import { styles } from './styles'
import { fonts } from './styles/fonts'
import * as colors from './styles/colors'

export const config = {
  colors,
  styles,
  fonts,
  radii: '2px',
  mode: 'light',
  showPlaygroundEditor: false,
  linesToScrollEditor: 18,
}
