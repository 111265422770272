export const imports = {
  'index.mdx': () =>
    import(/* webpackPrefetch: true, webpackChunkName: "index" */ 'index.mdx'),
  'experiments/beacon-apps.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "experiments-beacon-apps" */ 'experiments/beacon-apps.mdx'
    ),
  'experiments/inline-articles.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "experiments-inline-articles" */ 'experiments/inline-articles.mdx'
    ),
  'experiments/message-preview.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "experiments-message-preview" */ 'experiments/message-preview.mdx'
    ),
  'experiments/messages-custom-actions.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "experiments-messages-custom-actions" */ 'experiments/messages-custom-actions.mdx'
    ),
  'experiments/show-me.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "experiments-show-me" */ 'experiments/show-me.mdx'
    ),
}
